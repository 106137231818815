import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Peasley({ styles, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("form_heading").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-page-header"
        style={{
          backgroundColor: styles.forElement("first_background").backgroundColor
        }}
      >
        <div
          className="formkit-header-background"
          style={styles.forElement("first_background")}
        />
        <div className="formkit-container">
          <Elements.Content
            name="preheading"
            defaults={{
              content: "Free webinar"
            }}
          />
          <div className="formkit-border" style={styles.forElement("border")} />
          <Elements.Heading
            name="heading"
            defaults={{
              content: "Youtube for Beginners"
            }}
          />
          <Elements.Region className="formkit-subheading" name="subheading">
            <Elements.Content
              defaults={{
                content:
                  "Information about your webinar can go in here. Some info about what subscribers can expect to learn or experience, or introduction to the video below."
              }}
            />
          </Elements.Region>
          <Elements.Event
            group="date"
            name="date"
            className="formkit-date"
            style={styles.forElement("date")}
          />
        </div>
      </div>
      <div
        className="formkit-page-content"
        style={{
          backgroundColor: styles.forElement("second_background")
            .backgroundColor
        }}
      >
        <div
          className="formkit-content-background"
          style={styles.forElement("second_background")}
        />
        <div className="formkit-container">
          <Elements.Video name="video" className="formkit-negative-offset" />
          <div className="formkit-flex formkit-main-content">
            <div>
              <Elements.Heading
                className="formkit-content-heading"
                group="content_heading"
                name="content_heading_1"
                tag="h2"
                defaults={{
                  content: "Lesson 1"
                }}
              />
              <Elements.Region
                className="formkit-content"
                group="content_body"
                name="content_body_1"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "More information about your video or video series can go in here. Describe in more details the content and the benefits for the subscriber signing up to receive your videos in their inbox. More information about your video or video series can go in here. More information about your video or video series can go in here."
                  }}
                />
              </Elements.Region>
            </div>
            <div>
              <Elements.Heading
                className="formkit-content-heading"
                group="content_heading"
                name="content_heading_2"
                tag="h2"
                defaults={{
                  content: "Lesson 2"
                }}
              />
              <Elements.Region
                className="formkit-content"
                group="content_body"
                name="content_body_2"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "More information about your video or video series can go in here. Describe in more details the content and the benefits for the subscriber signing up to receive your videos in their inbox. More information about your video or video series can go in here. More information about your video or video series can go in here. "
                  }}
                />
              </Elements.Region>
            </div>
          </div>
        </div>
      </div>
      <div
        className="formkit-form-section"
        style={{
          backgroundColor: styles.forElement("third_background").backgroundColor
        }}
      >
        <div
          className="formkit-footer-background"
          style={styles.forElement("third_background")}
        />
        <div className="formkit-container">
          <div className="formkit-flex">
            <div className="formkit-author">
              <Elements.Image
                className="formkit-image"
                name="image"
                size={{ w: 118, h: 118 }}
                aspectRatio={1}
                shape="circle"
              />
              <Elements.Heading
                className="formkit-name"
                name="bio_heading"
                tag="h3"
                defaults={{
                  content: "John Smith"
                }}
              />
              <Elements.Region className="formkit-bio" name="bio_content">
                <Elements.Content
                  defaults={{
                    content:
                      "Short bio about you so that potential subscribers can get to know a little about you."
                  }}
                />
              </Elements.Region>
            </div>
            <div
              className="formkit-form-content"
              style={styles.forElement("form_background")}
            >
              <Elements.Heading
                className="formkit-form-heading"
                name="form_heading"
                tag="h2"
                defaults={{
                  content: "Start your channel today"
                }}
              />
              <Elements.Form>
                <Elements.Errors />
                <Elements.CustomFields
                  style={{
                    color: checkboxes_color
                  }}
                >
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Sign up" }}
                  />
                </Elements.CustomFields>
              </Elements.Form>
              <Elements.Content
                className="formkit-disclaimer"
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </div>
          </div>
          <Elements.BuiltWith
            background="third_background"
            image="third_background"
          />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Peasley.style = "landing_page"
Peasley.categories = ["Event", "Newsletter", "Profile", "Waitlist"]
Peasley.thumbnail = ""
Peasley.preview = "https://demo.ck.page/peasley"
Peasley.fields = [
  {
    name: "first_name",
    label: "First name"
  },
  {
    name: "email_address",
    label: "Email"
  }
]

export default createTemplate(Peasley, { name: "Peasley" })
